.taskConflictingSwitchGroup {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    border: solid 1px #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
}

.taskConflictingSwitchGroup .switchHeader {
    position: absolute;
    top: -12px;
    left: 0px;
    margin: 0;
    background: #fff;
    padding-right: 20px;
    padding-left: 10px;
    width: 100%;
    height: fit-content;
    background-color: transparent;
}

.switchHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 43px;
}

.taskConflictingSwitchGroup .switchHeader h2 {
    margin: 0;
    background-color: #ffffff;
}

.taskConflicted {
    border-color: #f88880;
}

.conflictResolved {
    border-color: #469b49;
}
