* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

:root {
  --color1: #e2951e;
  --color2: #e6e6e6;
  --color3: #ff9c01;
  --color4: #797979;
  --color5: #65A1B7;
  --low: #A8C186;
  --mid: #65A1B7;
  --high: #E2961E;
  --text: #1A1A1A;
}

body,
html {
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: 500;
}

span {
  font-size: 0.8125rem;
}

div#structure {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}

aside#menu {
  width: 200px;
  min-width: 200px;
  height: 100%;
  z-index: 99991;
  background-color: #ffffff;
  box-shadow: 0 0 4px #00000026;
  display: flex;
  flex-direction: column;
}

div#top_bar {
  height: 60px;
  background: #F9F9FB;
  box-shadow: 0 0 4px #00000026;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 10px;
  font-size: 12px;
  z-index: 9999;
  max-width: calc(100vw - 200px);
}

div#main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main-content {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background: #F9F9FB;
  overflow: auto;
}

div#logo {
  text-align: center;
  padding: 20px 35px;
  font-size: 45px;
  color: #00BCD4;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.menu_button_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;

}

button#menu_button {
  min-width: unset;
  padding: 0;
}


.flex_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}


.card {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  overflow: hidden;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  margin: 0;
}

.card_subtitle {
  color: #B5B5C3;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 10px;
  display: block;
  font-weight: 300;
}

#project_menu {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 40px;
}

#project_menu a {
  text-decoration: none;
  color: #1A1A1A;
}

h1#title {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

b {
  font-weight: 500;
}

h1#title b {
  font-size: 20px;
}



.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input_row {
  display: flex;
  gap: 8px;
}

.input_row_wrap {
  flex-wrap: wrap;
}

.inputColumn,
.input_column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.estimatePriceInput {
  width: 100%;
}


.box {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #000;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  width: fit-content;
}

.box100 {
  width: 100%;
}

.box50 {
  width: 50%;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 1px #bfbfbf;
  background: #fff;
}

.box_header {
  margin-bottom: 30px;
}

.box_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box_header {
  margin-bottom: 30px;
}

.input_group h2 {
  font-weight: 500;
  margin: 0;
}

.buttons_top {
  gap: 8px;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;

}

#ProjectHeader {
  gap: 10px;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}



#estimate_product_box {
  margin: unset !important;
  flex: 1 1 350px;
}

div#project_estimate_products {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}


.add_product_dotted_button {
  border: dashed 2px;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ababab;
  flex: 1 1 350px;
  cursor: pointer;
}

.estimates_th {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  min-width: 68px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 19px;
}

.estimates_prices_table th {
  padding: 5px;
}

.estimates_th_low {
  background: var(--low);
  color: #fff;
}

.estimates_th_mid {
  background: var(--mid);
  color: #fff;
}

.estimates_th_high {
  background: var(--high);
  color: #fff;
}

.estimate_design_fee {

  display: flex;
  align-items: center;
  line-height: 14px;
}

.estimate_design_fee_value {
  font-size: 0.8125rem;
}

.estimate_design_fee:before {
  content: "";
  border-bottom: solid 1px #9a9a9a;
  width: 100%;
  display: inline-block;
  height: 2px;
  margin-right: 5px;
}

.estimate_design_fee:after {
  content: "";
  border-bottom: solid 1px #9a9a9a;
  width: 100%;
  display: inline-block;
  height: 2px;
  margin-left: 5px;
}

.tc_estimate_design_fee {
  padding: 0 !important;
}

.estimates_prices_table td,
.estimates_prices_table th {
  border: none !important;
  padding: 5px !important;

}

.estimate_price_bold {
  font-weight: 500 !important;
  text-align: unset !important;
}

.estimate_price_value {
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 12px;
  text-align: center;
}

.box_editor {
  padding-bottom: 90px;
}

.forced_box_editor {
  padding-bottom: 90px !important;
}


.drawer_in {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 40px;
}


.subtitle {
  font-size: 11px;
}

.sidebar_body_row {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  align-items: center;
}

.epv_low {
  color: var(--low) !important;
}

.epv_mid {
  color: var(--mid) !important;
}

.epv_high {
  color: var(--high) !important;
}

.sidebar_body_row_left {
  display: flex;
  align-items: center;
}

.sidebar_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar_footer_in {
  margin-top: 20px;
}

.textButton {
  color: #000 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.btn_delete_corner {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: #989898 !important;
}

.drawer_content {
  padding: 0px 22px;
}

.contentWithMiniDrawer {
  display: flex;
  height: 100%;
  max-width: calc(100vw - 300px);
  margin: 0;
}

.div_flex {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.MuiTableRow-root:last-child td {
  border-bottom: 0;
}

.graph_row {
  display: flex;
  gap: 10px;
}

.graph_column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.graph_row .box {
  width: 100%;

}

.graph_top_boxes {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph_top_number {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #B5B5C3;
  line-height: 28px;
}

.graph_top_text {
  text-transform: uppercase;
  text-align: center;
  color: #B5B5C3;
  line-height: 19px;
}

div#graph_days_start {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 25px;
  white-space: nowrap;
  line-height: 25px;
  color: #B5B5C3;
}

div#graph_days_deadline {
  text-align: center;
  text-transform: uppercase;
  line-height: 14px;
  color: var(--color1);
}

div#financial_graph .box_content {
  height: 300px;
}

menu#project_menu {
  font-weight: 100;
}

.flex_select {
  display: none !important;
}

div.flex_select {
  display: flex !important;
  flex-direction: column;
  gap: 0px;
  align-items: start;
}

.flex_option_1 {
  font-size: 13px;
}

.flex_option_2 {
  color: #9e9e9e;
  font-size: 11px;
}

.MuiAutocomplete-option {
  display: flex !important;
  flex-direction: column;
  align-items: start !important;
}

.MuiAutocomplete-fullWidth {
  width: 100% !important;
}

.autocompleteRelative {
  position: relative;
}

.designFee_input {
  flex: 1 0 21%;
}

div#estimate_products_editor {
  max-width: 700px;
}

.SwitchGroup {
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
}

.flexSimple {
  display: flex;
  gap: 8px;
}

.SwitchGroup h2 {
  position: absolute;
  top: -11px;
  left: 11px;
  margin: 0;
  background: #fff;
  font-size: 12px;
  padding-right: 20px;
  padding-left: 14px;
}

#estimate_configuration h2,
#sendEmailDialog h2 {
  margin-bottom: 0;
}

.h2_dialog{
  margin-bottom: 0;
}

div#DashboardContainer,
div#mainDashboardContainer {
  max-width: 1600px;
}

div#QuoteContainter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.categoryRow {
  width: 100%;
  padding: 15px;
}

.dashedButton {
  border: dashed 2px !important;
  color: #ababab !important;
  width: 100%;
}

.QuoteService {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.QuoteServiceItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}


.QuoteServiceCategory {
  color: #fff;
  font-size: 14px;
  background: #ccc;
  padding: 6px 0px;
  transition: 0.5s;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

span.QuoteServiceText {
  font-size: 14px;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  text-transform: uppercase;
}

.serviceItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemRow {
  display: flex;
  gap: 10px;
}

.itemRow input {
  height: 100%;
}

span.autocomplete_product_code {
  padding: 0 10px;
  background: #cdcdcd;
  font-size: 11px;
  border-radius: 10px;
}


#autocomplete_product-listbox .MuiAutocomplete-option {
  flex-direction: row;
  align-items: center !important;
  gap: 10px;
}

.autocomplete_products {
  min-width: 400px;
}

.autocomplete_sections {
  min-width: 200px;
}

.autocomplete_ic_dots {
  padding: 0 8px !important;
}

.categoryRowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-transform: uppercase;
}

.categoryRowHeader h2 {
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.explodedCategoryRowHeader h2{
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.explodedCategoryRowHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-transform: uppercase;
}

.explodedCategoryRowHeader h2 {
  margin: 0;
}




.th_product {
  min-width: 400px;
  width: 40%;
}

.th_quantity {
  width: 10%;
}

.th_quote_section {
  width: 30%;
}

.th_quote_unit {
  width: 10%;
  text-align: right;
}

.th_quote_cost {
  width: 10%;
  text-align: right;
}

.table_quotes_thead_th {
  font-size: 10px;
}

.table_quotes_tbody {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type_millwork {
  background: var(--color1);
}

.type_stone {
  background: var(--color4);
}

.type_remodeling {
  background: var(--color5);
}

.QuoteServiceHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

#autocomplete_product-listbox .MuiAutocomplete-option span {
  font-size: 12px;
}

span.autocomplete_option_small {
  font-size: 12px;
}

.categoryRowTable th,
.measureRowtable th {
  font-size: 10px;
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
}

.align_right {
  text-align: right !important;
}

.categoryRowTable td,
.measureRowtable td {
  font-size: 12px;
}



#quote_sidebar {
  gap: 5px;
}

#quote_sidebar span {
  font-size: 12px;
}

.total_section .sidebar_body_row {
  border-top: solid 1px #ddd;
  padding: 10px 0;
}

.summary_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 3px;
}

.summary_section-service {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 3px;
}

.quote_total_row {
  padding: 5px !important;
  background: #eee;
  font-weight: 500 !important;
}

.total_section {
  margin-top: 50px;
}

.quoteSummaryTitle {
  font-size: 10px;
  color: #fff;
  background: #ccc;
  padding: 2px;
  text-align: center;
  border-radius: 3px;
}

.summary_row {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  padding: 0 5px;
}


.summary_row div {
  font-size: 12px;
}

.SummaryTitleStone,
.SummaryTitle-stone,
#SummaryTitleStone {
  background: var(--color4);
}

.SummaryTitleMillwork,
.SummaryTitle-millwork,
#SummaryTitleMillwork {
  background: var(--color1);
}

.SummaryTitleRemodeling,
.SummaryTitle-remodeling,
#SummaryTitleRemodeling {
  background: var(--color5);
}

.summary_total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #ddd;
  margin-top: 10px;
  padding-top: 10px;
}

.summary_total_label {
  font-weight: 300;
  font-size: 10px;
}

.summary_total_value {
  font-size: 21px;
}

.DialogTitle {
  padding-bottom: 20px;
}

.DialogHeader {
  margin-bottom: 20px;
}

.DialogHeader span {
  font-weight: 300;
  font-size: 13px;
}

.type_radio_group {
  flex-direction: row !important;
}

.radio_buttons_group {
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px 17px;
}

.item_add_button {
  background: #ddd !important;
  color: #5e5e5e !important;
  padding: 2px;
}

.stone_aditional_fields {
  display: flex;
  gap: 4px;
  align-items: center;
}

.stone_fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

span.switchLabel {
  font-size: 12px;
}

span.switchLabel {
  font-size: 12px;
}



.label_workforce {
  display: flex;
  justify-content: flex-end;
}

tr.stoneAuxFields td {
  padding-bottom: 20px;
}

.summary_row_left {
  display: flex;
  align-items: center;
  gap: 5px;
}

div#quoteExplodedView {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.sectionRow {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.sectionProductRowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionProductRowBodyFlex {
  display: flex;
  align-items: center;
}

.measureRowQuantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120px;
}

table.measureRowtable {
  width: 100%;
}

.measureRowQuantityValue {
  font-size: 30px;
  line-height: 30px;
}

.measureRowQuantityLabel {
  text-transform: uppercase;
  font-size: 12px;
}

.measureRowQuantityPrice {
  font-weight: 500;
  font-size: 18px;
}

.measuresTextTd {
  width: 150px;
}

.MeasureQuantityTd {
  width: 60px;
}

.deleteButtonTd {
  width: 30px;
}

.sectionProductRow {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.drawerImagesContainerDiv {
  position: relative;
}

.deleteImageButton {
  position: absolute !important;
  top: 0;
  right: 0;
}

.sectionProductRowHeader {
  padding: 12px;
  border-bottom: solid 1px #ccc;
}

div#filesBrowser {
  display: flex;
  flex-direction: row !important;
  gap: 10px;
  height: 100%;
  width: fit-content;
  padding-right: 20px;
}

.fs_row {
  gap: 5px;
  padding: 5px 25px !important;
  color: #959595 !important;
  justify-content: start !important;
  text-transform: inherit !important;
  white-space: nowrap;
}

.fs_forced_row {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;
  justify-content: start !important;
}

.box_file_browser {
  min-width: 200px;
  padding: 0;
}

.box_file_browser .box_content {
  gap: 0px;
  height: 100%;
}

.fs_row_selected,
.fs_row_selected .MuiTouchRipple-root {
  background: var(--color5);
}

.file.fs_row_selected .MuiTouchRipple-root {
  background: #65A1B755 !important;
}

.fs_row_selected .MuiTouchRipple-root {
  z-index: 0 !important;
  border-radius: 0 !important;
}

.file.fs_row_selected svg {
  fill: currentColor !important;
}

.fs_row_selected svg {
  /* fill: #fff !important; */
  fill: #237BA0 !important;
  z-index: 1;
}

.file.fs_row_selected span {
  color: #959595 !important;
}
.fs_row_selected span {
  color: #fff;
  z-index: 1;
}

.fs_row.folder svg {
  fill: #438299;
}

.level_folder_name {
  text-align: center;
  font-size: 10px;
  padding: 10px 25px;
  top: 7px;
  width: 100%;
  color: #ccc;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFolder_button svg {
  fill: var(--color5);
}

.addFile_button svg {
  fill: var(--color1);
}

.file_list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - var(--headers-height));
}

.box_file_browser .box_children {
  height: 100%;
}

.dropzone {
  height: 100%;

  --headers-height: 47px;
}

.file_list_with_header {
  --headers-height: 0px;
}

.dropzone_active {
  box-shadow: 0 0 10px var(--color1);
}

.progressBar {
  display: none !important;
  position: absolute !important;
  top: 0;
  width: 100%;
}

.show_progress_bar {
  display: block !important;
}

div#product_list_header {
  display: flex;
  gap: 10px;
}

div#product_list_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.product_list_header_item {
  flex: 1 1;
  border-bottom: 7px solid #E89750 !important;
  flex-direction: column;
  padding: 30px 20px 20px 20px !important;
  justify-content: center;
  align-items: center;
}

div#product_list {
  width: 100%;
}

.product_list_header_item img {
  height: 30px;
}

.product_list_header_item h3 {
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 0;
}

.product_list_header_item span {
  color: var(--color1);
  font-size: 12px;
}

.total_inventory {
  background: var(--color1);
  color: #fff;
  display: flex;
  padding: 20px !important;
  border: none !important;
}

.total_inventory span {
  color: #fff;
}

.total_inventory h3 {
  margin: 0;
}

.category_active {
  border: solid 1px var(--color1) !important;
  border-bottom: 7px solid #E89750 !important;
}

.category_active svg path {
  fill: var(--color1) !important;
}

.msg_save_quote p {
  white-space: break-spaces;
  text-align: center;
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 300;
}


.msg_save_quote svg {
  margin: auto;
  display: inherit;
}

.msg_save_quote svg path {
  fill: #a0a0a0;
}

#explodedViewImages #filesBrowser {
  width: 100% !important;
}

#explodedViewImages .box_file_browser {
  width: 100%;
}

#explodedViewImages .addFolder_button {
  display: none;
}

.file_thumnbnail_image {
  display: flex;
  flex-direction: column;
}

span.thumbnail_name {
  font-size: 10px;
}

.file_thumnbnail {
  display: flex;
  align-items: center;
  gap: 5px;
}

#MainDropMenu a {
  text-decoration: none;
  color: var(--text);
}

div#quote_list {
  width: 100%;
}

img.loginLogo {
  max-width: 150px;
  display: block;
  margin: auto;
}

div#loginBox {
  width: 400px;
  max-width: 90%;
}

div#loginBox {
  text-align: center;
  margin: auto;
}

div#loginBox p {
  font-size: 0.8em;
}

.loginContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
}

#dropFileDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.showOnlyOnHover {
  opacity: 0;
}

.showOnlyOnHover:hover {
  opacity: 1;
  background: #ffffffdb;
}

.drawerImagesContainerDiv {
  display: flex;
  width: 120px;
  height: 114px;
  justify-content: center;
  align-items: center;
  border: dashed 2px #ddd;
  position: relative;
}

.drawerImagesContainerDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone_active {
  border-color: var(--primary);
}

div#project_list_container {
  flex-direction: column;
}

.project_element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project_element .card_subtitle {
  margin-bottom: 0;
}

.project_element {
  padding: 15px 20px;
}

#project_menu .active {
  color: var(--color1);
}

h2#subtitle {
  margin: 0;
  font-weight: 300;
  color: #828282;
}

.order_product_row {
  display: flex;
  gap: 8px;
}

#order_editor .file_thumnbnail_image {
  max-width: 300px;
}

#order_editor .addFolder_button {
  display: none;
}

.stone_remnant_row,
.wood_dimension_row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.stone_remnant_row,
.wood_dimension_row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.boardFeet_value {
  min-width: 100px;
  display: flex;
  justify-content: center;
}

.stone_remnants, 
.wood_dimensions_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionProductRowHeaderRight {
  width: 100%;
}

.sectionServiceRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionServicesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type_section {
  text-align: center;
  background: #6d6d6d;
}

.sectionServiceHeader {
  background: #f9f9fb !important;
  text-transform: uppercase;
  color: #000;
}

.thSpan {
  font-size: 10px;
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
}



.quoteStatus {
  font-size: 0.8125rem;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 20px;
  border: dashed 1px;
}

.quoteStatus_1 {
  color: #ce5959 !important;
  border-color: #ce5959 !important;
}

/* 
.quoteStatus_2 {
  color: #ce5959;
}

.quoteStatus_3 {
  color: #ce5959;
}

.quoteStatus_4 {
  color: #ce5959;
}

.quoteStatus_5 {
  color: #ce5959;
}

.quoteStatus_6 {
  color: #ce5959;
}

.quoteStatus_7 {
  color: #ce5959;
}

.quoteStatus_8 {
  color: #ce5959;
}

.quoteStatus_9 {
  color: #ce5959;
}

.quoteStatus_10 {
  color: #ce5959;
} */

.quoteStatus_2 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_3 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_4 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_5 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_6 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_7 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_8 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_9 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}

.quoteStatus_10 {
  color: #e2951e !important;
  border-color: #e2951e !important;
}



.contentwithMiniDrawer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleQuoteType {
  padding: 10px 20px;
  border-radius: 30px;
  color: #808080;
  font-weight: 300;
  background: #ccc;
  cursor: pointer;
}


.ActiveViewButton {
  background: #e2951e;
  color: #fff;
}

.quoteStatusItem {
  display: flex;
  gap: 10px;
  align-items: center;
}

.quoteStatusItem__number {
  background: #6F6F6F;
  color: #fff;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.quoteStatusItem__date {
  font-size: 11px;
  font-weight: 300;
}

div#quoteStatusContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.quoteStatusItem__status {
  line-height: 15px;
  white-space: break-spaces;
}

h2.titleWIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quoteStatusItem:last-child .quoteStatusItem__number {
  background: #e09220;
}

div#ProjectHeaderButtons {
  display: flex;
  gap: 5px;
}

div#quotePreviewBox {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding-bottom: 70px;
}

.quotePreview__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

.quotePreview_flex_right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

span.quotePreview__header__right__title {
  color: #e3941e;
}

.quotePreview_flex_left {
  display: flex;
  flex-direction: column;
}

.quotePreview__row span {
  font-size: 13px;
}


span.quotePreview__clientInfo__title {
  font-weight: 400;
  color: #000;
}

.QuoteServiceHeaderLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.QuoteServiceHeaderRight {
  width: 300px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.quoteTextsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div#QuotePreviewSummary {
  width: 300px;
  text-align: right;
}

#QuotePreviewSummary .summary_total {
  flex-direction: row;
  justify-content: space-between;
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
}

#QuotePreviewSummary .summary_total .summary_total_label {
  font-size: 12px;
}

#QuotePreviewSummary .summary_total .summary_total_value {
  font-size: 12px;
}

#quotePreviewBox .QuoteServiceTotal {
  font-size: 12px;
}

.QuoteServiceTotal {
  width: -webkit-fill-available;
}

.QuoteServiceHeaderRight > div {
  position: relative;
  padding: .5rem 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 0 1 25%;
}

.QuoteServiceHeaderRight > div:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -2px;
  top: 25%;
  width: 2px;
  height: 50%;
  background-color: #888;
}

.box.product_form {
  height: fit-content;
  max-width: 750px;
}

.products_area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 1200px;
  margin: auto;
}

.drawer_content p {
  text-transform: inherit !important;
}

.drawer_content p {
  font-weight: 300;
}

.drawer_content span {
  font-weight: 300;
  max-width: 100%;
  white-space: break-spaces;
}

.addButtonCointainer {
  display: flex;
  justify-content: center;
}

.link_1 {
  font-size: 12px;
  color: var(--color1);
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: none;
}

.dx-scheduler-appointment-title {
  font-weight: 300;
}

.form_group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#loader {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: #fff;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  top:0;
  left:0;
  z-index: 99999999;
}

#loader.loaderHide {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

div#TopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div#TopBarLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

h1 {
  font-weight: 300;
  font-size: 20px;
}

.filtersForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filtersApplied {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
}

.filterItem {
  background: #ccc;
  padding: 0px 5px 0px 13px;
  font-size: 10px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 50px;
}

.filterItem span {
  font-size: 13px;
}

.activeDESC {
  transform: rotate(180deg);
}

.quoteStatusItem__right {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}



span.chip_code {
  background: #e8e8e8;
  padding: 0 9px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.liAutocomplete_product {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.td_2_rows {
  display: flex;
  gap: 5px;
  align-items: center;
}

.moduleContent {
  max-width: 1200px;
  margin: auto;
}

th {
  text-transform: uppercase;
  color: #6b6b6b !important;
}

div#clientListBox, div#pointsListBox {
  width: 100%;
}

a {
  font-size: 0.8125rem;
  color: inherit;
}

div#supplierListBox {
  width: 100%;
}


.explodedSections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionServiceItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.explodedCategoryRow {
  background: transparent;
  box-shadow: none;
  padding: 0;
}


.explodedSection {
  gap: 10px;
  margin-bottom: 0;
  padding: 10px;
}

.moduleContainer{
  max-width: 1200px;
  margin: auto;
}

h2.dialog_h2 {
  margin: 0;
}

.dashboard_flex_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard_left_column {
  max-width: 1000px;
}


.box_row_1 {
  background: #F2F2F2;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.box_row_1 h3 {
  margin: 0;
  font-weight: 500;
}

span.box_column_label {
  font-size: 10px;
  color: #b5b5b5;
  line-height: 7px;
}

.box_column_flex_column {
  display: flex;
  flex-direction: column;
  max-width: 270px;
}

span.box_column_value {
  font-size: 30px;
  line-height: 30px;
  color: #b5b5b5;
}

.flex_column_align_right {
  align-items: end;
}

span.no_order_label {
  background: #E56E6E;
  color: #fff;
  padding: 2px 10px;
  width: fit-content;
  font-size: 12px;
}
.stockPendingContent {
  display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 360px;
    overflow: auto;
}

.noProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #929292;
}

.noProducts img {
  opacity: 0.5;
}

div#dashboard_right_column {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;

}

div#dashboard_right_column .box {
  width: 100%;
}

div#product_list_flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.serviceClose .categoryRow {
  display: none;
}

.serviceClose .QuoteServiceText{
  display: none;
}

.serviceClose .QuoteServiceCategory{
  justify-content: center;
}

.serviceClose .ExplodedViewServieItems {
  flex-direction: unset;
}

.serviceOpen .ExplodedViewServieItems .QuoteServiceHeader{
  display:none
}

.serviceClose {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 30px #00000012;
}

.download_button {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.notHaveSection {
  display: none;
}


.forceShowSection {
  display: flex !important;
}

.flexTd {
  display: flex;
  flex-direction: column;
}

.flexTd_bold {
  font-weight: 500 !important;
}

.autoCompleteRenderedOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.autoCompleteRenderedOptions > .btn_delete_section {
  display: none;
}

.QuoteServiceTotal {
  display: flex;
  flex-direction: column;
  min-width: max-content;
  align-items: end !important;
}

.QuoteServiceTotal h5 {
  margin: 0;
  font-size: 15px;
  min-width: max-content;
  font-weight: 500;
}

.QuoteServiceTotal p {
  margin: 0;
  min-width: max-content;
  color: var(--color4);
  font-family: system-ui;
  font-weight: 500;
  line-height: normal;
  font-size: 11px;
}

.measuresTextTd {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  width: auto;
}

.dx-gantt-task.dx-gantt-parent {
  height: 25px !important;
  margin-top: -5px !important;
}

.dx-gantt-task {
  height: 26px !important;
  margin-top: -2px !important;
}

.dx-gantt-task.dx-gantt-parent .dx-gantt-taskTitle.dx-gantt-titleIn {
  margin-top: 3px;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 1pt;
}

.dx-gantt-task .dx-gantt-taskTitle.dx-gantt-titleIn {
  margin-top: 3px;
  font-size: 13px;
  font-weight: lighter;
}

.toogleOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 500;
  color: '#666';
}

.taskConflicting.SwitchGroup {
  padding: 10px 7px 7px;
}

#performanceContainer .moduleWrapper .moduleContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

div.top_box_list_header {
  display: flex;
  gap: 10px;
}

.top_box_list_header_item {
  flex: 1 1;
  border-bottom: 7px solid #E89750 !important;
  flex-direction: column;
  padding: 30px 20px 20px 20px !important;
  justify-content: center;
  align-items: center;
}

.performanceIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.performanceIndicator h3 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  color: #e3853d;
}

.empty_element {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.empty_element_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a0a0a0;
  font-size: 14px;
}

.empty_element_subtitle {
  font-weight: 300;
}

.empty_element_content img {
  opacity: 0.3;
}

span.empty_element_title {
  font-weight: 500;
}

#workertimmingUser .box_header h1 {
  font-size: 20px;
  font-weight: 500;
}

#workertimmingUser #project_list_container.flex_container {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 20px;
  flex-wrap: wrap;
}

#workertimmingUser #project_list_container.flex_container .card.project_element {
  width: 400px;
  padding: 0;
}

#workertimmingUser #project_list_container.flex_container .card.project_element .card_content {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

#workertimmingUser #project_list_container.flex_container .card.project_element .card_content .project_list_name {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}

#workertimmingEditor #project_list_container.flex_container,
#workertimmingEditor #project_completed_list_container.flex_container {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#workertimmingEditor #project_list_container .card.project_element {
  width: 400px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#workertimmingEditor #project_completed_list_container .card.project_element {
  width: 290px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#workertimmingEditor #project_list_container .card.project_element .card_header ,
#workertimmingEditor #project_completed_list_container .card.project_element .card_header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  padding-top: 10px;
}

#workertimmingEditor #project_list_container .card.project_element .card_content {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#workertimmingEditor #project_completed_list_container .card.project_element .card_content {
  padding: 5px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#workertimmingEditor #project_completed_list_container .card.project_element .card_content .card_element {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  margin: auto;
}

#workertimmingEditor #project_list_container .card.project_element .card_content .displayer_element .card_label ,
#workertimmingEditor #project_completed_list_container .card.project_element .card_content .displayer_element .card_label {
  font-size: 12px;
  color: black;
}

#workertimmingEditor #project_list_container .card.project_element .card_content .displayer_element .card_value ,
#workertimmingEditor #project_completed_list_container .card.project_element .card_content .displayer_element .card_value {
  font-size: 10px;
  color: gray;
}

#workertimmingEditor #project_list_container .card.project_element .card_content .card_element .card_label,
#workertimmingEditor #project_list_container .card.project_element .card_content .card_element .card_value,
#workertimmingEditor #project_completed_list_container .card.project_element .card_content .card_element .card_label,
#workertimmingEditor #project_completed_list_container .card.project_element .card_content .card_element .card_value,
.displayer_row .input_label,
.displayer_row .input_value {
  font-size: 12px;
  font-weight: 100;
}

#workertimmingEditor #project_list_container .card.project_element .card_footer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  width: 100%;
  padding: 0;
}

#workertimmingEditor #project_list_container .card.project_element .card_footer button {
  width: 100%;
  padding: 10px 0;
  border-radius: 0;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

#taskEditor #ProjectHeader {
  display: none;
}

.date-header {
  border-radius: 0;
  background-color: lightgray;
  color: black;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  align-self: center;
  justify-content: center;
  width: 100%;
}

.dotted_row {
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: lightgray;
}

.dotted_row div {
  border-bottom-style: solid;
  border-bottom-width: 6px;
  border-bottom-color: #fff;
  margin-top: 5px;
  margin-bottom: -5px;
}

#superviseWorkersContainer table thead tr th {
  font-size: 12px;
  font-weight: 500;
  color: black;
}


#superviseWorkersContainer table thead tr th:nth-child(4) ,
#superviseWorkersContainer table tbody tr td:nth-child(4) ,
#superviseWorkersContainer table thead tr th:nth-child(2) ,
#superviseWorkersContainer table tbody tr td:nth-child(2) ,
#superviseWorkersContainer table thead tr th:last-child ,
#superviseWorkersContainer table tbody tr td:last-child {
  width: 160px;
}

#superviseWorkersContainer table thead tr th:first-child ,
#superviseWorkersContainer table tbody tr td:first-child {
  width: 30%;
}

#superviseWorkersContainer table thead tr th:last-child ,
#superviseWorkersContainer table tbody tr td:last-child {
  text-align: right;
  width: 150px;
}

#superviseWorkersContainer table tbody tr td.tableDate {
  background-color: #666;
  color: white;
  padding: 5px 10px;
  text-align: center;
}

#superviseWorkersContainer table tbody tr td {
  font-size: 12px;
  font-weight: 100;
  color: black;
  padding: 10px;
}

#superviseWorkersContainer.moduleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;
}

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.dashboardWrapper .dashboard_flex_container .box {
  width: 100%;
}

.dashboardWrapper .contentWithMiniDrawer {
  max-width: unset;
  width: 100%;
}

.transparent_box {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 20px 10px;
}

.graph_top_icon {
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
}

#indicators .graph_top_boxes {
  height: 168px;
  padding: 10px 5px;
  color: #626262;
  
  background-color: #EAEAEA;

  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

#indicators .graph_top_boxes .graph_top_number {
  color: #626262;
}

.graph_top_title {
  text-transform: uppercase;
  text-align: center;
  color: #B5B5C3;
  line-height: 19px;
}

#indicators .graph_top_boxes .graph_top_title {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
}

#indicators .graph_top_boxes .graph_top_text {
  color: #626262;
  font-size: 14px;
  font-weight: 300;
}

.graph_top_container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  height: 100%;
}

.graph_top_progress_bar {
  width: 100%;
  height: 10px;
  background-color: #EAEAEA;
  border-radius: 0px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.graph_top_progress_bar div {
  height: 10px;
  background-color: var(--low);
}

#worktime_indicators .graph_top_boxes {
  height: 140px;
}

#worktime_indicators .graph_top_boxes.primary_box {
  width: 250%
}

.task_list_row_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  max-width: 400px;
  width: fit-content;
  padding: 10px;
  height: fit-content;
  border-radius: 5px;
  margin: 0 2px;
}

.task_list_row_item.completed {
  background-color: var(--low);
  color: #6C834B;
}

.task_list_row_item.pending {
  background-color: #D9D9D9;
  color: #797979;
}

.task_list_row_item .task_list_row_item_name {
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
  
  width: max-content;
}

.task_list_row_item .task_list_row_item_name span {
  font-size: 10px;
}

#list_task_dashboard .box_header .card_title {
  font-size: 14px;
  font-weight: 500;
  color: #626262;
}

#list_task_dashboard .displayer_row_text {
  font-size: 14px;
  font-weight: 500;
  color: #222;

  min-width: 20%;
}

#list_task_dashboard .task_list_row {
  max-width: 80%;
  overflow-x: auto;
}

.ellipsed-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
}

#used-inventory-container .ellipsed-label {
  max-width: 200px;
}

.hidden_scroll_row_container {
  overflow-y: hidden;
}

.hidden_scroll_row_container .hidden_scroll_row_box {
  display: flex;
  flex-direction: row;
  position: relative;
  height: calc(100% + 17px);
  top: 0;
  bottom: -17px;
  overflow-x: scroll !important;
}

#list_task_dashboard .hidden_scroll_row_container {
  height: 40px;
}

#list_task_dashboard .hidden_scroll_row_container .displayer_row_text {
  display: flex;
  align-items: center;

  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  padding-left: 18px;
}

#list_task_dashboard .hidden_scroll_row_container .displayer_row_text span {
  font-size: 12px;
}

#dashboard_files_box {
  overflow-x: auto;
  overflow-y: clip;
}

#dashboard_files_box .file_list{
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
}

#dashboard_finaltial_container .box_header,
#dashboard_schedule_box .box_header,
#dashboard_progress_box .box_header,
#dashboard_files_box .box_header,
.without-subtitle-bottom .box_header {
  padding: 0 10px;
  margin-bottom: 0;
}

#dashboard_progress_box .box_header .card_subtitle {
  margin-bottom:0px;
}

#dashboard_schedule_box .moduleContainer {
  max-width: unset;
  width: 100%;
}

#dashboard_schedule_box .title-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#dashboard_payment_container .graph_top_boxes:first-child,
#payroll_details_container .graph_top_boxes:first-child {
  background-color: var(--color1);
  color: white;
}

#dashboard_payment_container .graph_top_boxes:first-child .graph_top_number,
#dashboard_payment_container .graph_top_boxes:first-child .graph_top_text,
#payroll_details_container .graph_top_boxes:first-child .graph_top_number,
#payroll_details_container .graph_top_boxes:first-child .graph_top_text {
  color: white;
}

#dashboard_payment_indicators {
  display: flex;
  gap: 10px;
}

#dashboard_payment_container .graph_top_boxes .graph_top_number {
  font-size: 40px;
}

#dashboard_payment_indicators .graph_top_container {
  display: flex;
  flex-direction: row;
  gap: 0px;
  width: 100%;
  height: auto;
}

#dashboard_payment_indicators .graph_top_progress_bar {
  width: 10px;
  height: 100%;
  background-color: #d8d8d8;
  border-radius: 0px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#dashboard_payment_indicators .graph_top_progress_bar div {
  height: 10px;
  background-color: var(--low);
}

#dashboard_payment_indicators .graph_top_boxes {
  padding: 0 10px 0 10px;
  width: 100%;
  background-color: white;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#dashboard_payment_indicators .graph_top_boxes :first-child {
  width: 100%;
}

#dashboard_payment_indicators .graph_top_boxes .displayer_row span {
  width: 100%;
}

#dashboard_payment_indicators .graph_top_boxes .displayer_row span:first-child {
  text-align: left;
  font-weight: 300;
  white-space: nowrap;
}

#dashboard_payment_indicators .graph_top_boxes .displayer_row span:last-child {
  text-align: right;
}

#dashboard_payment_indicators .graph_top_icon.displayer_row {
  color: #B5B5C3;
}

#list_task_dashboard div.box:first-child,
#dashboard_payments_list_container div.box:first-child {
  padding: 30px 30px 20px;
}

#dashboard_payments_list_container {
  min-width: 355px;
}
.emptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #929292;
}

.emptyBox img {
  opacity: 0.5;
  max-width: 80px;
}

.emptyBox span,
.emptyBox p {
  text-align: center;
}

.displayer_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  width: 100%;
}

.list_task_state {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
  padding: 0;
  margin: 0;
}

#list_task_dashboard .list_task_state span {
  font-size: 10px;
  font-weight: 300;
  color: #000;
}
#list_task_dashboard .list_task_state_icon {
  width: 9px;
  height: 9px;

  --OK-color: #6C834B;
  --nOK-color: #E56E6E;
}

#list_task_dashboard .list_task_state_icon.assigned {
  color: var(--OK-color);
}

#list_task_dashboard .list_task_state_icon.unassigned {
  color: var(--nOK-color);
}

#list_task_dashboard .box_header {
  padding: 0;
  margin-bottom: 0;
}

#files_container .contentWithMiniDrawer,
#files_container .contentWithMiniDrawer #filesBrowser,
#files_container .contentWithMiniDrawer #filesBrowser .box_file_browser {
  width: 100%;
}

#DashboardContainer #ProjectHeader {
  padding: 0 10px;
}

#superviseWorkersContainer .moduleWrapper {
  height: 100%;
}

#superviseWorkersContainer .moduleContent {
  height: 100%;
}

#superviseWorkersContainer .box.box100 {
  height: 100%;
}

#dialog_product_inventory #products_list .input_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0px 0px
}

.table_actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  
  padding: 0px 0px 5px;
}


#ProjectList .moduleContainer .moduleContainer_header {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#ProjectList .moduleContainer .moduleContainer_header .buttons_top {
  align-items: center;
}

#ProjectList .moduleContainer #project_list_container .box_content .project_list_name .card_subtitle {
  margin: 0;
}

.absolute_button {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.fixed_button {
  position: fixed !important;
  right: 5px;
  top: 5px;
}

.fullWidth {
  width: 100% !important;
}

*::-webkit-scrollbar, #editor_flotante_productos::-webkit-scrollbar {
  width: 0.75em;
}

*::-webkit-scrollbar-thumb, #editor_flotante_productos::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  outline: 1px solid slategrey;
}

#customLoader.loaderHide {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

#customLoader.loaderView {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 19;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  background-color: #fff;

}

.board {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  margin: 0;
  width: 100%;

  overflow-x: auto;
  overflow-y: auto;
}

.board .column_board {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 20px;
  margin: 0;
  min-width: 320px;
  height: 100%;
  margin-bottom: 20px;

  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #B5B5C240 !important;
  border-radius: 5px;
  opacity: 1;
}

.board .column_board .box_children {
  width: 100%;
}

#note_detailed_form_editor .box_header .card_title,
#note_detailed_form_editor .card_title,
.board .column_board .box_header .card_title,
.board .column_board .note_box {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: bold;
  color: #3f4254;
  letter-spacing: -1px;
}

#note_detailed_form_editor .box_header .card_title,
#note_detailed_form_editor .card_title {
  letter-spacing: 0px;
}

.board .column_board .note_container {
  width: 320px;
}

.board .column_board .note_box {
  background-color: white;
  width: 100%;
  box-shadow: none;
  border: none;
  box-shadow: 0px 0px 10px #4C577D25;
  border-radius: 5px;
}

.board .column_board .note_box .note_thumbnail {
  width: 100%;
  max-height: 230px;
  background-color: #ddd;
  border-radius: 5px 5px 0 0;

  text-align: center;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.board .column_board .note_box .note_thumbnail img {
  width: 100%;
  height: fit-content;
}
.board .column_board .note_box .input_group {
  width: 100%;
  padding: 10px;
  padding-top: 0;
  margin: auto;
  cursor: pointer;
}

.board .column_board .note_box .displayer_row {
  width: 100%;
  justify-content: space-between;
}

.board .column_board .note_box .displayer_row .input_label:first-child {
  font-weight: bolder;
}

.board .column_board .note_box .note_content {
  padding: 0;
  margin: 0;
  width: 100%;
  color: #000;
  font-size: 14px;
}

.board .column_board .note_box .buttons {
  justify-content: space-between;
  box-shadow: 0 0 1px #000;
}

.board .column_board .note_container.note_dragging {
  z-index: 20;
}

.board .column_board .note_container .note_title {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 300;
  color: #000;
  padding: 20px;
  padding-bottom: 0px;
  letter-spacing: 0.5px;
}

#note_detailed_form_editor .input_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

}

#payroll_details_container.graph_row {
  gap: 5px;
}

#payroll_details_container .graph_top_boxes .graph_top_number {
  font-size: 20px;
}

#payroll_details_container .graph_top_boxes,
#dashboard_quick_access_box .graph_top_boxes {
  padding: 20px;
}

#payroll_details_container .graph_top_boxes .graph_top_text {
  line-height: 0;
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
  font-weight: 100;
}

#payroll_details_container .graph_top_boxes .graph_top_number,
#payroll_details_container .graph_top_boxes .graph_top_text {
  color: #000;
}

#payroll_details_container .graph_top_boxes {
  background-color: var(--color2);
}

#notesContainer {
  max-width: calc(100% - 100px);
  min-height: -webkit-fill-available;
  margin: 0;
}

#notesContainer .moduleWrapper {
  max-width: 100%;
  min-height: -webkit-fill-available;
  height: calc(100vh - 125px);
}

#notesContainer .moduleWrapper .moduleContent {
  margin: 0;
  max-width: 100%;
  min-height: 100%;
}

#notesContainer .moduleWrapper .moduleContent .boardContainer {
  min-height: 100%;
  height: calc(100vh - 125px);
}

#notesContainer .moduleWrapper .moduleContent .boardContainer .board {
  min-height: 100%;
}

#notesContainer .moduleWrapper .moduleContent .boardContainer .board .box_header {
  margin-bottom: 0;
}

#notesContainer .moduleWrapper .moduleContent .boardContainer .board .box_header .MuiChip-root {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

#notesContainer .moduleWrapper .moduleContent .boardContainer .board .box_header .MuiChip-label {
  font-size: inherit;
  padding: 2px;
}

.message_info {
  display: flex;
  flex-direction: row;
}

.message {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 10px 10px;

  border-radius: 5px;
  border: solid 1px #0000000F;
  background-color: #fff;

  font-size: 13px;
  font-weight: 300;
}

.message_info span:first-child {
  font-weight: bold;
}

#message-container {
  display: flex;
  flex-direction: column;
  
  gap: 20px;
}

#message-container .avatar-container {
  align-self: baseline;
}

.message_buttons,
.message_buttons a {
  color: #aaa;
  padding: 0 5px;
  cursor: pointer;

  text-decoration: none;
}

.small-text {
  font-size: 10px !important;
}

.padding-off {
  padding: 0 !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-20 {
  padding: 20px !important;
}

.gap-off {
  gap: 0px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.file_thumnbnail_list,
.file_thumnbnail_list_image {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px !important;
}

.file_thumnbnail_list .file_thumbnail,
.file_thumnbnail_list_image .file_thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
  overflow-y: hidden;
  overflow-x: hidden;

  color: #666 !important;
  background-color: #ededed;
}

.file_thumnbnail_list .file_thumbnail span {
  color: #666 !important;
  text-align: center;
}

.file_thumnbnail_list span,
.file_thumnbnail_list_image span {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #555;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;

  width: 100%;
  text-align: left;
}

span.thumbnail_list_name {
  font-size: 10px;
}

.file_thumnbnail_list {
  display: flex;
  align-items: center;
  gap: 5px;
}

.viewThumbnailList,
.viewThumbnailList #filesBrowser,
.viewThumbnailList #filesBrowser .box_file_browser,
.viewThumbnailList #filesBrowser .box_file_browser .box_children,
.viewThumbnailGallery,
.viewThumbnailGallery #filesBrowser,
.viewThumbnailGallery #filesBrowser .box_file_browser,
.viewThumbnailGallery #filesBrowser .box_file_browser .box_children,
.viewSlideGallery,
.viewSlideGallery #filesBrowser,
.viewSlideGallery #filesBrowser .box_file_browser,
.viewSlideGallery #filesBrowser .box_file_browser .box_children
{
  width: 100%;
}

.viewSlideGallery {
  max-width: 280px;
}
.viewSlideGallery #filesBrowser {
  padding: 0px;
}

.viewSlideGallery .navigation_row {
  display: flex !important;
  flex-direction: row;
}

.viewSlideGallery .navigation_row .thumbnail_button {
  width: 100%;
  padding: 0px !important;
}

.viewSlideGallery .navigation_row .thumbnail_button .file_thumnbnail {
  min-width: 206px;
  min-height: 278px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 100%;
}

.viewSlideGallery .navigation_row .thumbnail_button .file_thumnbnail_image img {
  width: 100%;
  height: auto;
}

.viewSlideGallery .navigation_row .thumbnail_button .file_thumnbnail .file_thumbnail {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666 !important;
  text-align: center;
  background-color: #eee;
}

.viewSlideGallery .navigation_row .thumbnail_button .file_thumnbnail_image {
  height: 253px;
  width: 206px;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.viewSlideGallery .navigation_row .thumbnail_button .file_thumnbnail .file_thumbnail_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.viewThumbnailList #filesBrowser .box_file_browser .box_children,
.viewThumbnailGallery #filesBrowser .box_file_browser .box_children
{
  margin: 0;
}

.viewThumbnailGallery .file_list {
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;
}

.viewThumbnailGallery .thumbnail_button {
  max-width: calc(33% - 5px); /* Width for 3 thumbnails minus the gap */
  height: 253px;
  padding: 10px !important;
}

.viewThumbnailGallery .thumbnail_button .file_thumnbnail_image {
  width: 100%;
}

.viewThumbnailGallery .thumbnail_button .thumbnail_name {
  text-wrap: pretty;
}

.viewThumbnailGallery .thumbnail_button:not(.image_file) {
  min-width: 100%;
}

.viewThumbnailGallery .delete_button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; */
  background-color: #0006;
  color: #fff;
  padding: 5px;
  padding-bottom: 1px;
}

#note_detailed_form_banner_container {
  width: 100%;
  height: 234px;
  /* max-height: 234px;
  min-height: 234px;
  overflow-y: hidden; */
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
}

#note_detailed_form_banner_container img {
  height: 100%;
  width: fit-content;
}

.board .column_board .note_box .note_info {
  padding: 20px !important;
  padding-top: 10px !important;
}

.board .column_board .note_box .note_info span:first-child {
  font-size: 14px;
  font-weight: bold;
}

.board .column_board .note_box .note_info span:last-child {
  font-size: 9px;
  font-weight: 100;
}

.padding-40 {
  padding: 40px !important;
}

.padding-30 {
  padding: 30px !important;
}

.file_thumnbnail_list .file_thumbnail_info .download_button {
  text-decoration: none;
}
.file_thumnbnail_list .file_thumbnail_info,
.file_thumnbnail_list_image .file_thumbnail_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.file_thumnbnail_list .file_thumbnail_info :not(.thumbnail_name),
.file_thumnbnail_list_image .file_thumbnail_info :not(.thumbnail_name) {
  font-size: 12px;
  font-weight: 300;
  color: #797979;
  text-transform: none;
}

.box.gap-20 .box_content {
  gap: 20px;
}

.dropzone_with_overlay.dropzone .dropzone_overlay {
  height: 100%;
  display: none;
}

.dropzone_with_overlay.dropzone_active .dropzone_overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.dropzone_with_overlay.dropzone_active :not(.dropzone_overlay) {
  display: none !important;
}

.dropzone_with_overlay.dropzone_active .dropzone_overlay span {
  display: block !important;
  font-size: 30px;
  font-family: 'Poppins';
  letter-spacing: 8px;
  /* text-shadow: 1px 1px #fc7, 2px 2px #fc7, 3px 3px #fc74, -1px -1px #fc7, -2px -2px #fc7, -3px -3px #fc74; */
  color: var(--color4);
}

#note_detailed_form_editor .box {
  background: transparent;
  box-shadow: none;
}

#note_detailed_form_editor .card_info {
  margin-top: 0;
  width: 100%;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}

.gap-10 {
  gap: 10px;
}

#files_drawer #drawer_back_icon  {
  margin-top: 5px;
  margin-right: 5px;
}

#files_drawer .drawer_header {
  align-items: flex-start;
  padding: 25px 15px;
}

#files_drawer .file-info-header {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

#files_drawer .file-info-header .file-title {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: black;
  font: normal normal 500 14px/23px Poppins;
}

#files_drawer .file-info-header .file-subtitle {
  font: normal normal 300 11px/18px Poppins;
  letter-spacing: 0px;
  color: #797979;
}

.button-icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.basic_comments_box {
  width: 100%;
  padding: 10px;
  background-color: #F0F0F0;
}

#files_drawer p,
.basic_comments_box p {
  font: normal normal 300 12px/20px Poppins;
  width: 100%;
  text-align: left;
  text-wrap: balance;
}

.basic_comments_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#files_drawer .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

#files_drawer .actions .button-icon-column {
  padding: 20px;
}

#files_drawer .actions .button-icon-column .MuiButton-startIcon {
  margin: 0;
}

#files_drawer .card_title {
  font: normal normal 500 14px/23px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

#files_drawer .file-info-header-container {
  gap: 0;
}

#files_drawer #version-history-timeline .file-info-header .file-title {
  white-space: unset;
}

.fc-primary {
  color: var(--color1) !important;
}

.cursor_pointer {
  cursor: pointer;
}

#mainDashboardContainer .dashboard_left_column {
  width: 1100px;
  max-width: unset;
}

#mainDashboardContainer #dashboard_main_indicators_box #dashboard_payment_container .graph_top_boxes img {
  width: 52px;
  max-width: unset;
}

#mainDashboardContainer #dashboard_main_indicators_box #dashboard_payment_container .graph_top_boxes .payment_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

#dashboard_top_indicators_box .box {
  padding: 25px;
}

.graph_legends_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.graph_legends_container .graph_legends_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.graph_legends_container .graph_legends_item .graph_legends_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.graph_legends_container .graph_legends_item .graph_legends_data span {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #778191;
}

.graph_legends_container .graph_legends_item .graph_legends_data span.graph_legends_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

#dashboard_top_projects_indicators_box .table_row .table_data {
  color: #B5B5C3;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dashboard_projects_count_box {
  background-color: #D9D9D9;
}

#dashboard_projects_count_box .box_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
}

#dashboard_projects_count_box .graph_top_number,
#dashboard_projects_count_box .graph_top_text {
  color: #797979;
}

#dashboard_projects_count_box .graph_top_number {
  font-size: 40px;
}

#dashboard_projects_count_box .graph_top_text {
  font-size: 15px;
  text-transform: unset;
}

.field_badge {
  position: absolute;
  width: 13px !important;
  height: 13px !important;
  top: -3px !important;
  right: -3px !important;
  color: var(--color4);
  z-index: 1;
}

#product_list_table .link_1 {
  gap: 10px;
}

.ic_autocomplete_button {
  background: linear-gradient(to right, #ffbd00 0%, #ffbd00 50%, #ff9a00 50%, #ff9a00 100%);
  width: 22.3px;
  height: 22.3px;
  padding: 4px !important;
}

.ic_autocomplete_button > svg {
  width: 100%;
  fill: #FFF;
}

.only-on-hover-container .only-on-hover-content {
  display: none;
}

.only-on-hover-container:hover .only-on-hover-content {
  display: block;
}

*:has(> .end-icon) {
  position: relative;
}

.end-icon {
  position: absolute !important;
  right: 0;
  top: 0;
}

*:has(> .hide_parent_container) {
  display: none;
}